<template>
  <div>
    <GoBack title="User Profile Settings"></GoBack>
    <ValidationObserver v-slot="{ invalid }" tag="div" ref="observer" class="profile">
      <section class="form">
        <div class="form__row form__row--mb-50">
          <div class="form__row_col form__row_col--side">
            <div class="profile__img">
              <UploadImage
                class="image--lg"
                :image="user.avatar"
                :initials="user.initials"
                @upload="upload"
              ></UploadImage>
              <div class="profile__img_text">
                <h6>{{ user.name_abbreviation }}</h6>
                <p>Collector</p>
              </div>
            </div>
          </div>
          <div class="form__row_col form__row_col--side">
            <div class="form__actions">
              <BaseButton
                class="button--sm button--outline button--uppercase"
                text="change password"
                @click="
                  $router.push({ path: `/change-password?email=${user.email}&reset_password=1` })
                "
              ></BaseButton>
              <BaseButton
                class="button--sm button--black button--uppercase"
                text="logout"
                @click.prevent="apiLogoutUser"
              ></BaseButton>
            </div>
          </div>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="First Name"
              :required="true"
              :validateError="errors[0]"
              v-model="user.first_name"
              :element="user.first_name"
              @input="user.first_name = $event"
            ></BaseInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Last Name"
              :required="true"
              :validateError="errors[0]"
              v-model="user.last_name"
              :element="user.last_name"
              @input="user.last_name = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Email"
              :required="true"
              :validateError="errors[0]"
              v-model="user.email"
              :element="user.email"
              @input="user.email = $event"
            >
            </BaseInput>
          </ValidationProvider>
          <div class="form__row_col form__row_col--side">
            <BaseInput
              placeholder="Phone"
              v-model="user.phone"
              :element="user.phone"
              @input="user.phone = $event"
            ></BaseInput>
          </div>
        </div>
        <div class="form__row" v-if="user.new_email">
          <div class="form__row_col form__row_col--side">
            <BaseInput
              class="form-control--icon form-control--icon-15"
              placeholder="New Email"
              :icon="true"
              :disabled="true"
              v-model="user.new_email"
              :element="user.new_email"
              @input="user.new_email = $event"
            >
              <template #btn>
                <BaseButton
                  class="button--xsm button--outline button--uppercase"
                  text="verify"
                  :loading="verifyLoading"
                  textColor="#000"
                  @click="verify"
                ></BaseButton>
              </template>
            </BaseInput>
          </div>
        </div>
      </section>
      <div class="form__actions">
        <BaseButton
          class="button--sm button--outline button--uppercase"
          text="Discard Changes"
          :disabled="!changed"
          @click="discardChanges"
        ></BaseButton>
        <BaseButton
          class="button--sm button--green button--uppercase"
          text="Save Changes"
          :loading="loading"
          :disabled="!changed || invalid"
          @click="saveChanges"
        ></BaseButton>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import GoBack from "@/components/GoBack.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import UploadImage from "@/components/UploadImage.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    GoBack,
    ValidationObserver,
    ValidationProvider,
    UploadImage,
    BaseInput,
    BaseButton,
  },
  data() {
    return {
      loading: false,
      verifyLoading: false,
      user: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        avatar: "",
        new_email: "",
      },
      changed: false,
    };
  },
  created() {
    this.user = JSON.parse(JSON.stringify(this.userInfo));
  },
  computed: {
    ...mapGetters("user", {
      userInfo: "getUserInfo",
    }),
  },
  watch: {
    userInfo(val) {
      this.user = JSON.parse(JSON.stringify(val));
    },
    user: {
      handler: function (val, oldVal) {
        if (oldVal.email != "" && this.$refs.observer.flags.dirty) {
          this.changed = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("auth", ["apiLogoutUser", "apiSendVerify"]),
    ...mapActions("user", ["apiPutUserInfo"]),
    ...mapActions("general", ["apiPostUploadImage"]),
    async upload(file) {
      let image = await this.apiPostUploadImage(file);
      if (image) {
        this.user.avatar = image;
      }
    },
    discardChanges() {
      this.user = JSON.parse(JSON.stringify(this.userInfo));
    },
    async verify() {
      this.verifyLoading = true;
      await this.apiSendVerify({ verify_email: this.user.new_email });
      this.verifyLoading = false;
    },
    async saveChanges() {
      this.loading = true;
      let result = await this.apiPutUserInfo(this.user);
      this.loading = false;
      if (result) {
        this.changed = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.profile {
  padding: 5.85rem 6.5rem 5.2rem;
  border-radius: 16px;
  background-color: $white;
  border: 0.5px solid $lightGrey;
  .form {
    margin-bottom: 4rem;
    &__actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      .button {
        margin-left: 20px;
      }
    }
  }
  &__img {
    display: flex;
    align-items: center;
    &_text {
      margin-left: 25px;
      h6 {
        @include type($fs: 2rem, $lh: 1.2, $fw: 600);
        text-transform: capitalize;
        margin-bottom: 2px;
        cursor: pointer;
        color: $black;
        @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
          font-size: 16px;
        }
      }
      p {
        @include type($fs: 1.5rem, $lh: 1.25, $fw: 500);
        color: #868588;
        text-transform: capitalize;
        @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
          font-size: 12px;
        }
      }
    }
  }
}
</style>