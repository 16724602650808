<template>
  <div class="go-back" @click="$router.go(-1)">
    <span class="go-back__icon rotate--270"
      ><IconArrowTop width="21" height="12"></IconArrowTop
    ></span>
    <h3>{{ title }}</h3>
  </div>
</template>
<script>
import IconArrowTop from "@/components/icons/IconArrowTop.vue";
export default {
  props: { title: { type: String } },
  components: { IconArrowTop },
};
</script>
<style lang="scss" scoped>
.go-back {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
  cursor: pointer;
  h3 {
    @include type($fs: 3.4rem, $lh: 1.23, $fw: 600);
    margin-left: 15px;
    color: $black;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 26px;
    }
  }
}
</style>